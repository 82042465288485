import React, { useEffect, useState } from 'react';
import { FormDataConsumer, CheckboxGroupInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Typography, Grid, Box, Divider, Checkbox, FormControlLabel } from '@mui/material';

import { Theme } from 'src/Theme';
import {
    ChildFormFields,
    EducationLevelList,
    YesNoChoices,
    SpecialEducationRequirementsList,
    DisabilityGroupList,
    DisabilityReasonList,
    SocialStatusLabels
} from 'src/constants';
import {
    ShsTextInput,
    ShsRadioButtonGroup,
    ShsDatePicker,
    ShsSelectInput,
    ShsCheckbox,
    ShsAutoCompleteWithLoad
} from 'src/components/common';

export const EducationChildData: React.FC = () => {
    const [isOtherSocialStatus, setIsOtherSocialStatus] = useState(false);
    const [educationCenterId, setEducationCenterId] = useState<undefined | number>(undefined);

    const formContext = useFormContext();
    const { defaultValues } = formContext.formState;

    useEffect(() => {
        setIsOtherSocialStatus(Boolean(defaultValues?.visit?.otherSocialStatus));
        setEducationCenterId(defaultValues?.visit?.hospitalDepartmentId || 0);
    }, [defaultValues]);

    return (
        <>
            <Typography variant="h5" component="h5" mb={3} fontWeight="600" color={Theme.palette.primary.midnightBlue}>
                Відомості про освіту дитини в школі супергероїв
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={6}>
                    <ShsSelectInput
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_LEVEL}`}
                        label="Рівень освіти"
                        choices={EducationLevelList}
                        required
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={6}>
                    <ShsAutoCompleteWithLoad
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATION_CENTER}`}
                        pageUrl="centers"
                        // emptyText=""
                        keyField="id"
                        textField="shortName"
                        resultLimit={100}
                        label="Освітній центр"
                        filters={{ active: true }}
                        onChange={(id: number) => {
                            formContext.setValue('visit.hospitalDepartmentId', null);
                            setEducationCenterId(id);
                            formContext.trigger('visit.hospitalDepartmentId');
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsAutoCompleteWithLoad
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.HOSPITAL_DEPARTMENT}`}
                        pageUrl="hospital-departments"
                        keyField="id"
                        textField="departmentName"
                        resultLimit={100}
                        label="Відділення де знаходиться дитина"
                        filters={educationCenterId ? { educationCenterId } : undefined}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsDatePicker
                        labelPosition="top"
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_AGREEMENT_DATE}`}
                        label="Дата згоди на надання освітніх послуг"
                        required
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={12}>
                    <ShsRadioButtonGroup
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS}.enabled`}
                        label="Особа з особливими освітніми потребами"
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        choices={YesNoChoices}
                        optionValue="value"
                        onChange={(value: unknown) => {
                            if (!value) {
                                formContext.setValue('visit.specialRequirements.parameters', []);
                            }
                        }}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const isSpecialRequirements =
                                formData[ChildFormFields.VISIT]?.[ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS]
                                    ?.enabled;
                            return (
                                isSpecialRequirements && (
                                    <Box sx={{ mt: -2 }}>
                                        <Typography
                                            component="div"
                                            fontWeight="400"
                                            color={Theme.palette.primary.optional}>
                                            Адаптація освітнього процесу під потреби учнів
                                        </Typography>
                                        <CheckboxGroupInput
                                            sx={{ color: Theme.palette.primary.midnightBlue }}
                                            source={`${ChildFormFields.VISIT}.${ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS}.parameters`}
                                            choices={SpecialEducationRequirementsList}
                                            row={false}
                                            label={''}
                                        />
                                    </Box>
                                )
                            );
                        }}
                    </FormDataConsumer>
                    <Divider sx={{ mb: 2, mt: 0 }} />
                </Grid>

                <Grid item xs={12}>
                    <ShsRadioButtonGroup
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.DISABILITY}`}
                        label="Дитина має інвалідність"
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        choices={YesNoChoices}
                        optionValue="value"
                        onChange={(value: unknown) => {
                            if (!value) {
                                formContext.setValue('visit.disabilityGroup', null);
                                formContext.setValue('visit.disabilityReason', null);
                            }
                        }}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const isDisabilityPresent = formData[ChildFormFields.VISIT]?.[ChildFormFields.DISABILITY];
                            return (
                                isDisabilityPresent && (
                                    <>
                                        <Box sx={{ mt: 0 }}>
                                            <Typography
                                                component="div"
                                                fontWeight="400"
                                                color={Theme.palette.primary.optional}>
                                                Група інвалідності
                                            </Typography>
                                            <ShsRadioButtonGroup
                                                sx={{ color: Theme.palette.primary.midnightBlue }}
                                                source={`${ChildFormFields.VISIT}.${ChildFormFields.DISABILITY_GROUP}`}
                                                choices={DisabilityGroupList}
                                                label={''}
                                                required
                                            />
                                        </Box>
                                        <Box sx={{ mt: 0 }}>
                                            <Typography
                                                component="div"
                                                fontWeight="400"
                                                color={Theme.palette.primary.optional}>
                                                Причина інвалідності
                                            </Typography>
                                            <ShsRadioButtonGroup
                                                sx={{ color: Theme.palette.primary.midnightBlue }}
                                                source={`${ChildFormFields.VISIT}.${ChildFormFields.DISABILITY_REASON}`}
                                                choices={DisabilityReasonList}
                                                label={''}
                                                required
                                            />
                                        </Box>
                                    </>
                                )
                            );
                        }}
                    </FormDataConsumer>
                    <Divider sx={{ mb: 2, mt: 0 }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="div" mb={2} fontWeight="500" color={Theme.palette.primary.midnightBlue}>
                        Соціальний статус
                    </Typography>
                    <Box display="flex" flexDirection="column">
                        <Typography component="span" mb={1} fontWeight="400" color={Theme.palette.primary.optional}>
                            Відмітьте пункти, які відносяться до дитини
                        </Typography>
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={`${ChildFormFields.VISIT}.${ChildFormFields.ORPHAN}`}
                            label={SocialStatusLabels[ChildFormFields.ORPHAN]}
                        />
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={`${ChildFormFields.VISIT}.${ChildFormFields.DISPLACED}`}
                            label={SocialStatusLabels[ChildFormFields.DISPLACED]}
                        />
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={`${ChildFormFields.VISIT}.${ChildFormFields.WAR_VICTIM}`}
                            label={SocialStatusLabels[ChildFormFields.WAR_VICTIM]}
                        />
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={`${ChildFormFields.VISIT}.${ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES}`}
                            label={SocialStatusLabels[ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES]}
                        />
                        <FormControlLabel
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            control={
                                <Checkbox
                                    checked={isOtherSocialStatus}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={() => {
                                        if (isOtherSocialStatus) {
                                            formContext.setValue('visit.otherSocialStatus', '');
                                        }
                                        setIsOtherSocialStatus(!isOtherSocialStatus);
                                    }}
                                />
                            }
                            label="Інше"
                        />
                    </Box>
                    {isOtherSocialStatus && (
                        <ShsTextInput
                            source={`${ChildFormFields.VISIT}.${ChildFormFields.OTHER_SOCIAL_STATUS}`}
                            label="."
                            placeholder="Напишіть"
                            required
                            label-sx={{ height: 0, visibility: 'hidden' }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <ShsRadioButtonGroup
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        source={`${ChildFormFields.VISIT}.${ChildFormFields.MEDIA_RECORDING_AGREEMENT}`}
                        label="Згода на проведення фото-, кіно-, теле- та відеозйомки дитини"
                        choices={YesNoChoices}
                        optionValue="value"
                        onChange={(value: unknown) => {
                            if (!value) {
                                formContext.setValue('visit.mediaRecordingLink', null);
                            }
                        }}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const isAgreement =
                                formData[ChildFormFields.VISIT]?.[ChildFormFields.MEDIA_RECORDING_AGREEMENT];
                            return (
                                isAgreement && (
                                    <Box mt={-2}>
                                        <ShsTextInput
                                            source={`${ChildFormFields.VISIT}.${ChildFormFields.MEDIA_RECORDING_LINK}`}
                                            label="."
                                            placeholder="Вкажіть лінк на файл згоди"
                                            label-sx={{ height: 0, visibility: 'hidden' }}
                                        />
                                    </Box>
                                )
                            );
                        }}
                    </FormDataConsumer>
                    <Box>
                        <ShsTextInput
                            source={`${ChildFormFields.VISIT}.${ChildFormFields.CHILD_INFO}`}
                            label="Додаткова інформація, яку необхідно знати"
                            inputProps={{ maxLength: 255 }}
                            multiline
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default EducationChildData;
