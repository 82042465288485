import React, { useEffect, useState } from 'react';
import { useLogin, useNotify, useStore } from 'react-admin';
import { ReactComponent as Logo } from 'src/assets/logo.svg';
import { ReactComponent as DiiaLogo } from 'src/assets/diia-button.svg';
import { Tab, Tabs } from '@mui/material';
import { STATUS } from 'src/constants';
import './index.css';
import QrCode from './QrCode';
import axiosApiInstance from 'src/api/axiosConfig';
import { EducationCenterType } from '../Employees/types';
import { StoreKey } from 'src/store/enums/store-key';
import ShsAlert from '../common/ShsAlert';
import { ErrorType } from '../common/ShsAlert/constants';

const LoginPage: React.FC = () => {
    const [educationalCenter, setEducationalCenter] = useStore<EducationCenterType['id'] | undefined>(
        StoreKey.EDUCATIONAL_CENTER
    );

    const [taxNumber, setTaxNumber] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [eduCenterList, setEduCenterList] = useState<EducationCenterType[]>([]);
    const [showQrCode, setShowQrCode] = useState<boolean>(false);
    const fakeAuthEnabled: boolean = process.env.REACT_APP_ENABLE_FAKE_AUTH === 'true';
    const [diiaActivated, setDiiaActivated] = useState<STATUS.ACTIVE | STATUS.INACTIVE>(
        fakeAuthEnabled ? STATUS.INACTIVE : STATUS.ACTIVE
    );

    const login = useLogin();
    const notify = useNotify();

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => setTaxNumber(e.currentTarget.value);

    const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value);

    const handleShowQrCode = () => {
        if (!educationalCenter) {
            notify('Оберіть навчальний центр');
        } else {
            setShowQrCode(true);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!educationalCenter) {
            notify('Оберіть навчальний центр');
        } else {
            login({ taxNumber, password, educationalCenter })
                .then(() => {
                    axiosApiInstance.get(`notifications`).then((response) => {
                        response.data.forEach((element: any) => {
                            if (element.type === ErrorType.MEDICAL_CHECKUP) {
                                notify(ShsAlert({ type: ErrorType.MEDICAL_CHECKUP }), {
                                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                                });
                            }
                        });
                    });
                })
                .catch(() => notify('Неправильний РНОКПП чи пароль'));
        }
    };

    const getTemporaryAccess = () => {
        //setDiiaActivated(false);
        setShowQrCode(false);
    };

    useEffect(() => {
        axiosApiInstance.get('/centers/names').then((response) => {
            setEduCenterList(response.data);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page_container">
                <div className="login_container">
                    <Logo />
                    {fakeAuthEnabled && (
                        <Tabs
                            value={diiaActivated}
                            onChange={(e, value) => {
                                if (value == STATUS.INACTIVE) setShowQrCode(false);
                                setDiiaActivated(value);
                            }}>
                            <Tab value={STATUS.INACTIVE} label="Тимчасовий вхід" />
                            <Tab value={STATUS.ACTIVE} label="Вхід через Дію" />
                        </Tabs>
                    )}

                    {diiaActivated == STATUS.INACTIVE && fakeAuthEnabled && (
                        <div>
                            <form onSubmit={handleSubmit} className="login_form" noValidate>
                                <input
                                    className="login_form__input"
                                    name="taxNumbers"
                                    type="text"
                                    pattern="[0-9]{10}"
                                    placeholder="РНОКПП"
                                    value={taxNumber}
                                    onChange={handleInputChange}
                                />
                                <input
                                    className="login_form__input"
                                    name="password"
                                    type="password"
                                    placeholder="Пароль"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                                <select
                                    className="login_form__select"
                                    style={{
                                        color: !educationalCenter ? '#9ca3af' : 'black'
                                    }}
                                    value={educationalCenter}
                                    onChange={(e) => setEducationalCenter(Number(e.target.value))}>
                                    <option value="" disabled selected hidden>
                                        Виберіть Освітній Центр
                                    </option>
                                    {eduCenterList.map((x) => {
                                        return (
                                            <option value={x.id} key={x.id}>
                                                {x.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <button className="login_from__login_button">Увійти</button>
                            </form>
                            <button className="login__forgot_password">Забули пароль?</button>
                        </div>
                    )}
                    {diiaActivated == STATUS.ACTIVE && !showQrCode && (
                        <div className="login_form_diia">
                            <div className={`login-diia-info ${fakeAuthEnabled ? '' : 'fake-auth-not-enabled'}`}>
                                Авторизуйтесь для входу в кабінет
                            </div>
                            <select
                                className="login_form__select"
                                style={{
                                    color: !educationalCenter ? '#9ca3af' : 'black'
                                }}
                                value={educationalCenter}
                                onChange={(e) => setEducationalCenter(Number(e.target.value))}>
                                <option value="" disabled selected hidden>
                                    Виберіть Освітній Центр
                                </option>
                                {eduCenterList.map((x) => {
                                    return (
                                        <option value={x.id} key={x.id}>
                                            {x.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="login-diia-button-container">
                                <button className="login-diia-qr-button" onClick={handleShowQrCode}>
                                    <DiiaLogo />
                                </button>
                            </div>
                        </div>
                    )}
                    {diiaActivated == STATUS.ACTIVE && (
                        <QrCode showQrCode={showQrCode} educationalCenterId={educationalCenter} />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoginPage;
