import { Box } from '@mui/material';

interface InfoCardProps {
    icon: React.ReactNode;
    number: number;
    label: string;
}

/**
 * Displays informational card with icon, number, and label
 */
export const InfoCard: React.FC<InfoCardProps> = ({ icon, number, label }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '1.5em',
                    height: '1.5em',
                    borderRadius: '0.5em',
                    padding: 0,
                    marginRight: '0.5em'
                }}>
                {icon}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                <Box sx={{ fontSize: '0.875em', fontWeight: 'bold', lineHeight: '0.875em' }}>{number}</Box>
                <Box sx={{ fontSize: '0.625em', fontWeight: 'normal', color: '#555' }}>{label}</Box>
            </Box>
        </Box>
    );
};
