import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as GroupIcon } from 'src/assets/employee/group-reporting.svg';
import { ReactComponent as IndividualIcon } from 'src/assets/employee/individual-reporting.svg';
import { ReactComponent as TotalIcon } from 'src/assets/employee/total-reporting.svg';
import { InfoCard } from './InfoCard';

const styleDivider = {
    color: '#D9D9D9',
    fontWeight: 200,
    fontSize: '20px'
};

interface LessonSectionProps {
    countData: {
        groupCount: number;
        individualCount: number;
        totalCount: number;
    };
}

/**
 * Displays number of group, individual, and total lessons
 */
export const LessonSection: React.FC<LessonSectionProps> = ({ countData }) => {
    return (
        <Box
            sx={{
                flex: '1 1 50%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.7em'
                }}>
                <InfoCard icon={<GroupIcon fontSize="small" />} number={countData?.groupCount} label="Групові" />
                <span style={styleDivider}>|</span>
                <InfoCard
                    icon={<IndividualIcon fontSize="small" />}
                    number={countData?.individualCount}
                    label="Індивідуальні"
                />
                <span style={styleDivider}>|</span>
                <InfoCard icon={<TotalIcon fontSize="small" />} number={countData?.totalCount} label="Загалом" />
            </Box>
        </Box>
    );
};
