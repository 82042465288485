import { ReactComponent as Logo } from 'src/assets/notification.svg';

export enum ErrorType {
    MEDICAL_CHECKUP
}

export const notificationTypes = {
    [ErrorType.MEDICAL_CHECKUP]: {
        title: 'Пройдіть медичний огляд!',
        message: `Термін дії Вашого останнього медичного огляду закінчується.
        Для подальшого доступу до системи необхідно пройти новий медичний огляд. Будь ласка, заплануйте ваш огляд якнайшвидше. `,
        severity: 'info',
        icon: <Logo fontSize="inherit" />
    }
};
