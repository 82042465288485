import { Alert, AlertProps, AlertTitle } from '@mui/material';
import { ErrorType, notificationTypes } from './constants';

type ShsAlertProps = AlertProps & {
    type: ErrorType;
};

const ShsAlert = ({ type }: ShsAlertProps) => {
    const { title, message, icon, severity } = notificationTypes[type];

    return (
        <Alert severity={severity as AlertProps['severity']} icon={icon} sx={{ width: '400px' }}>
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    );
};

export default ShsAlert;
