import * as React from 'react';
import { Box, Stack, Select, MenuItem, Typography } from '@mui/material';
import { ShsButton } from 'src/components/common';
import { ReactComponent as LeftIcon } from 'src/assets/chevron-left.svg';
import { ReactComponent as RightIcon } from 'src/assets/chevron-right.svg';

const toolbarStyles = {
    margin: 0,
    padding: '0 0 16px',
    justifyContent: 'space-between',
    '& .MuiButtonBase-root': {
        height: '40px',
        padding: '0 12px !important',
        lineHeight: '40px !important'
    },
    '& .MuiButtonBase-root.with-icon': {
        minWidth: 'unset',
        width: '40px !important',
        padding: '0 !important'
    },
    '& .with-icon .MuiButton-startIcon': {
        m: 0,
        width: '40px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

const selectStyles = {
    width: '160px',
    height: '40px',
    borderRadius: '6px'
};

export const ToolbarViewList = [
    { id: 'week', name: 'Тиждень' },
    { id: 'month', name: 'Місяць' },
    { id: 'allTime', name: 'За весь період' }
];

interface ToolbarProps {
    date: Date;
    view: string;
    label: string;
    handleNavigate: (action: string) => void;
    handleViewChange: (view: string) => void;
}

const EmployeeReportToolbar: React.FC<ToolbarProps> = ({ label, view, handleViewChange, handleNavigate }) => {
    const [viewValue, setViewValue] = React.useState(view);

    const handleChangeView = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newView = event.target.value;
        setViewValue(newView);
        handleViewChange(newView);
    };

    return (
        <Box>
            <Box className="rbc-toolbar" sx={toolbarStyles}>
                <Stack direction="row" spacing={2}>
                    <ShsButton
                        label="Cьогодні"
                        styleType="filled-white"
                        onClick={() => handleNavigate('TODAY')}
                        disabled={view === 'allTime'}
                    />
                    <ShsButton
                        label=""
                        styleType="filled-white"
                        icon={<LeftIcon />}
                        className="with-icon"
                        onClick={() => handleNavigate('PREVIOUS')}
                        disabled={view === 'allTime'}
                    />
                    <ShsButton
                        label=""
                        styleType="filled-white"
                        icon={<RightIcon />}
                        className="with-icon"
                        onClick={() => handleNavigate('NEXT')}
                        disabled={view === 'allTime'}
                    />
                    {view !== 'allTime' && (
                        <Typography component="div" fontWeight="600" fontSize="16px" lineHeight="40px" pl={1}>
                            {label}
                        </Typography>
                    )}
                </Stack>
                <Select sx={selectStyles} value={viewValue} label="" onChange={handleChangeView as any}>
                    {ToolbarViewList.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    );
};

export default EmployeeReportToolbar;
