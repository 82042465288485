import React, { useState, useEffect } from 'react';
import axiosApiInstance from '../../api/axiosConfig';
import QRCode from 'qrcode.react';
import CountDown from './CountDownTimer';
import { EducationCenterType } from '../Employees/types';
import { useNotify } from 'react-admin';
import ShsAlert from '../common/ShsAlert';
import { ErrorType } from '../common/ShsAlert/constants';

interface DiiaQrCodeProps {
    showQrCode: boolean;
    educationalCenterId: EducationCenterType['id'] | undefined;
}

const QrCode: React.FC<DiiaQrCodeProps> = ({ showQrCode, educationalCenterId }) => {
    const [refreshQrKey, setrefreshQrKey] = useState<number>(1);
    const [refreshCountDownKey, setrefreshCountDownKey] = useState<number>(1);
    const [qrData, setQrData] = useState<string>('');
    const [requestId, setRequestId] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    let initialCountDownValue: number = 180;

    const refreshQrCode = () => {
        setrefreshQrKey(refreshQrKey + 1);
    };

    const notify = useNotify();

    useEffect(() => {
        if (showQrCode) {
            axiosApiInstance
                .get('/auth/diia', {
                    headers: {
                        'Education-Center': educationalCenterId
                    }
                })
                .then((response) => {
                    setQrData(response.data.deeplink);
                    setRequestId(response.data.requestId);
                    setrefreshCountDownKey(refreshCountDownKey + 1);
                    setErrorMessage('');
                    axiosApiInstance.get(`notifications`).then((response) => {
                        response.data.forEach((element: any) => {
                            if (element.type === ErrorType.MEDICAL_CHECKUP) {
                                notify(ShsAlert({ type: ErrorType.MEDICAL_CHECKUP }), {
                                    anchorOrigin: { vertical: 'top', horizontal: 'right' }
                                });
                            }
                        });
                    });
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [showQrCode, refreshQrKey]);

    return (
        <div className="diia-qrcode">
            <div>{showQrCode && <div className="login-diia-info">Зчитайте QR-код сканером у застосунку Дія</div>}</div>
            <div className="qr-code">{showQrCode && qrData && <QRCode value={qrData} width={216} height={216} />}</div>

            {showQrCode && qrData && (
                <CountDown
                    refreshCountDownKey={refreshCountDownKey}
                    initialCountDownValue={initialCountDownValue}
                    requestId={requestId}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}

            <div className="login-diia-button-container">
                {showQrCode && qrData && (
                    <button className="login-diia-refresh" onClick={refreshQrCode}>
                        Оновити Qr-код
                    </button>
                )}
            </div>
        </div>
    );
};

export default QrCode;
