import React, { useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { format, addMonths, addWeeks, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import uk from 'date-fns/locale/uk';

import axiosApiInstance, { postRequestCacheConfig } from 'src/api/axiosConfig';
import { EmployeeFormFields } from 'src/constants/employee';
import EmployeeReportToolbar from '../../form/EmployeeReportToolbar';
import { LessonsSection } from './LessonReport';

interface LessonCount {
    groupCount: number;
    individualCount: number;
    totalCount: number;
}

/**
 * Renders the Employee Report Tab, allowing users to view
 * lessons provided by an employee in a specified time range (month, week, or all)
 */
export const EmployeeReportTab: React.FC<{ selectedCenterId: string }> = ({ selectedCenterId }) => {
    const record = useRecordContext();
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const [startDate, setStartDate] = React.useState<Date | undefined>(new Date());
    const [endDate, setEndDate] = React.useState<Date | undefined>(new Date());
    const [staffLessons, setStaffLessons] = React.useState<Record<string, LessonCount>>();
    const [civilianLawLessons, setCivilianLawLessons] = React.useState<Record<string, LessonCount>>();
    const [view, setView] = React.useState('month');

    const employees = record[EmployeeFormFields.EMPLOYEES_CENTERS] || [];
    const currentEmployeeCenter = employees.find((employee: any) => {
        return employee.educationCenterId == selectedCenterId;
    });

    // Set the date range when the component mounts and when the view changes
    useEffect(() => {
        setDateRange(currentDate, view);
    }, [currentDate, view]);

    const setDateRange = (currentDate: Date, view: string) => {
        if (view === 'month') {
            setStartDate(startOfMonth(currentDate));
            setEndDate(endOfMonth(currentDate));
        } else if (view === 'week') {
            setStartDate(startOfWeek(currentDate, { locale: uk }));
            setEndDate(endOfWeek(currentDate, { locale: uk }));
        } else {
            // "all" view, don't set start and end dates
            setStartDate(undefined);
            setEndDate(undefined);
        }
    };

    // Effect for data fetching that triggers when startDate/endDate changes
    useEffect(() => {
        if (currentEmployeeCenter) {
            const requestData = {
                userId: record.id,
                educationCenterId: currentEmployeeCenter?.educationCenterId
            };

            let url = `employees/reporting`;

            if (startDate !== undefined && endDate !== undefined) {
                const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd');
                const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd');

                url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
            }

            axiosApiInstance
                .post(url, requestData, postRequestCacheConfig)
                .then((res) => {
                    setStaffLessons(res.data.staffReporting);
                    setCivilianLawLessons(res.data.civilianLawNatureReporting);
                })
                .catch((e) => {
                    throw e;
                });
        }
    }, [startDate, endDate, currentEmployeeCenter, record.id, view]);

    /**
     * Handles view change events
     * @param { string } newView - new view to switch to ('month', 'week', or 'all')
     */
    const handleViewChange = (newView: string) => {
        setView(newView);

        if (newView === 'all') {
            setStartDate(undefined);
            setEndDate(undefined);
        } else {
            setDateRange(currentDate, newView);
        }
    };

    /**
     * Handles navigation events (previous, next, today)
     * @param { string } action - navigation action ('PREVIOUS', 'NEXT', or 'TODAY')
     */
    const handleNavigate = (action: string) => {
        let newDate = currentDate;

        if (view === 'month') {
            if (action === 'PREVIOUS') newDate = addMonths(currentDate, -1);
            if (action === 'NEXT') newDate = addMonths(currentDate, 1);
            if (action === 'TODAY') newDate = new Date();
        } else if (view === 'week') {
            if (action === 'PREVIOUS') newDate = addWeeks(currentDate, -1);
            if (action === 'NEXT') newDate = addWeeks(currentDate, 1);
            if (action === 'TODAY') newDate = new Date();
        }

        setCurrentDate(newDate);
    };

    // Effect for updating the date range when the "Today" button is clicked
    useEffect(() => {
        if (view !== 'all') {
            setDateRange(currentDate, view);
        }
    }, [currentDate, view]);

    /**
     * Gets the label for the current date range
     */
    const getLabel = () => {
        if (view === 'month') {
            const monthLabel = format(currentDate, 'LLLL yyyy', { locale: uk });
            return monthLabel.charAt(0).toUpperCase() + monthLabel.slice(1);
        } else if (view === 'week') {
            return `${startDate && format(startDate, 'd MMMM', { locale: uk })} - ${endDate && format(endDate, 'd MMMM yyyy', { locale: uk })}`;
        } else if (view === 'all') {
            return 'За весь період';
        } else {
            return '';
        }
    };

    return (
        <>
            <EmployeeReportToolbar
                date={currentDate}
                view={view}
                label={getLabel()}
                handleViewChange={handleViewChange}
                handleNavigate={handleNavigate}
            />
            {staffLessons && (
                <LessonsSection
                    title="Надано освітніх послуг (штатно)"
                    generalCount={staffLessons?.generalCount!}
                    lessonNamesToCount={staffLessons?.lessonNamesToCount!}
                />
            )}
            {civilianLawLessons && (
                <LessonsSection
                    title="Надано освітніх послуг (ЦПХ)"
                    generalCount={civilianLawLessons?.generalCount!}
                    lessonNamesToCount={civilianLawLessons?.lessonNamesToCount!}
                />
            )}
        </>
    );
};

export default EmployeeReportTab;
