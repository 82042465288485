import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Confirm, useSaveContext } from 'react-admin';
import { Typography, Box } from '@mui/material';

import { shsModalsStyles, interFontStyle } from 'src/styles';
import CircleWarning from 'src/assets/CircleWarning';
import { ShsButton } from 'src/components/common';

import { ModalProps } from '../../common/ShsFormToolbar';

const modalsStyles = {
    ...shsModalsStyles,
    '& .MuiPaper-root': {
        maxWidth: '588px',
        borderRadius: '20px'
    },
    '& .MuiDialogContent-root': {
        padding: 0,
        '> .shs-modal-body': {
            padding: '0 73px'
        }
    },
    '& .shs-modal-footer': {
        padding: '20px 50px 50px',
        '> .MuiButton-root': {
            minWidth: '235px',
            padding: '13px 20px',
            '&.outline': {
                color: '#111928',
                border: '1px solid #DFE4EA'
            }
        }
    }
};

const modalsEditStyles = {
    ...modalsStyles,
    '& .shs-modal-footer': {
        padding: '20px 50px 50px',
        '> .MuiButton-root': {
            minWidth: '235px',
            padding: '13px 13px',
            '&.outline': {
                color: '#111928',
                border: '1px solid #DFE4EA'
            }
        }
    }
};

type ModalPropsKids = ModalProps & {
    handleValidationError?: () => void;
};

/**
 * `CancelEditModal` renders a confirmation modal when the user attempts to cancel
 *  an edit or adding of a child entity in the form. The modal allows the user to either:
 * - Confirm canceling with optional data-saving prompt
 * - Close the modal without saving
 * - Open a validation error modal if validation fails
 */
export const CancelEditModal: React.FC<ModalPropsKids> = ({
    isOpen,
    isEdit,
    onClose,
    onCancel,
    handleValidationError
}) => {
    const { isDirty } = useFormState();
    const { save } = useSaveContext();
    const { handleSubmit } = useFormContext();

    /**
     * Handles the save button click by submitting the form data
     * Saves form data if save context is available
     * Displays validation error modal if submission fails
     */
    const handleSaveBtnClick = async () => {
        const submitData = handleSubmit(
            async (data) => {
                save?.(data);
            },
            () => {
                handleValidationError && handleValidationError();
            }
        );

        try {
            await submitData();
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    return (
        <>
            <Confirm
                isOpen={isOpen}
                loading={false}
                title={''}
                onConfirm={onClose}
                onClose={onClose}
                sx={isEdit ? modalsEditStyles : modalsStyles}
                content={
                    <>
                        <div className="shs-modal-header">
                            <Box sx={{ textAlign: 'center' }}>
                                <CircleWarning />
                            </Box>
                            <Typography variant="h5" component="h5" mb={2} mt={1} fontWeight="600" align="center">
                                {isEdit
                                    ? isDirty
                                        ? 'Редагування дитини'
                                        : 'Скасувати редагування дитини'
                                    : 'Скасувати додавання дитини'}
                            </Typography>
                        </div>
                        <div className="shs-modal-body">
                            <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                                {isEdit
                                    ? isDirty
                                        ? 'Ви внесли зміни у профіль дитини. Якщо ви скасуєте редагування зараз, усі незбережені зміни буде втрачено. Чи бажаєте ви зберегти зміни перед виходом?'
                                        : 'Чи впевнені ви що хочете скасувати редагування дитини?'
                                    : isDirty
                                      ? 'Чи впевнені ви що хочете скасувати додавання дитини? Після скасування додавання дитини всі дані про нього будуть видалені й не підлягатимуть відновленню. Чи бажаєте ви зберегти дані перед виходом?'
                                      : 'Чи впевнені ви що хочете скасувати додавання дитини?'}
                            </Typography>
                        </div>
                        <div className="shs-modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ShsButton
                                label={isEdit ? 'Скасувати редагування' : 'Скасувати додавання'}
                                styleType="outline"
                                onClick={onCancel}
                                sx={{ flex: '1', marginRight: '1em' }}
                            />
                            {isDirty ? (
                                <ShsButton
                                    label={isEdit ? 'Зберегти зміни' : 'Зберегти дані'}
                                    styleType="filled-danger"
                                    onClick={handleSaveBtnClick}
                                    sx={{ flex: '1' }}
                                />
                            ) : (
                                <ShsButton
                                    label={isEdit ? 'Продовжити редагування' : 'Продовжити додавання'}
                                    styleType="filled-danger"
                                    onClick={onClose}
                                    sx={{ flex: '1' }}
                                />
                            )}
                        </div>
                    </>
                }
            />
        </>
    );
};
