import React from 'react';
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { LessonSection } from './LessonSection';

interface LessonsSectionProps {
    title: string;
    generalCount: {
        groupCount: number;
        individualCount: number;
        totalCount: number;
    };
    lessonNamesToCount: LessonCount;
}

interface LessonCount {
    groupCount: number;
    individualCount: number;
    totalCount: number;
}

/**
 * Displays a section of lessons with their general and detailed counts
 */
export const LessonsSection: React.FC<LessonsSectionProps> = ({ title, generalCount, lessonNamesToCount }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    padding: '1em 0'
                }}>
                <Box sx={{ flex: '1 1 50%' }}>{title}</Box>
                <LessonSection countData={generalCount} />
            </Box>

            {lessonNamesToCount &&
                Object.entries(lessonNamesToCount).map(([lessonName, lessonData]) => {
                    return (
                        <Accordion
                            key={lessonName}
                            disableGutters
                            sx={{
                                boxShadow: 'none'
                            }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`${lessonName}-content`}
                                id={`${lessonName}-header`}
                                sx={{
                                    boxShadow: 'none',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    padding: '1em 0',
                                    flexDirection: 'row-reverse'
                                }}>
                                <Box sx={{ flex: '1 1 50%' }}>
                                    <Typography>{lessonName}</Typography>
                                </Box>
                                <LessonSection countData={lessonData} />
                            </AccordionSummary>
                        </Accordion>
                    );
                })}
        </>
    );
};
